<template>
  <div class="pdp-headline">
    <h5 v-html="headline" />
  </div>
</template>

<script>

export default {
  props: {
    headline: {
      type: String,
      default() {
        return ''
      }
    }
  }

}
</script>

<style lang="scss">
.pdp-headline {
  margin-top: 0;

  h5 {
    margin-top: 0;
    margin-bottom: .5rem;
  }
}
</style>
