<template>
  <div
    class="datepicker-wrapper enext-input"
    :class="[
      $attrs?.class ? $attrs.class : ''
    ]"
  >
    <kendo-datepicker
      class="datepicker"
      ref="datePicker"
      :size="'small'"
      :fill-mode="'solid'"
      v-bind="customAttrs"
      :format="userDateFormat"
      @iconclick="$emit('toggle-calender')"
    >
      <template
        v-for="(_, slot) of $slots"
        #[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope"
        />
      </template>
    </kendo-datepicker>
  </div>
</template>

<script>
import {DatePicker} from '@progress/kendo-vue-dateinputs'
import inputValidationMixin from '~/mixins/input_validation'
import {storeToRefs} from 'pinia'
import {useUserStore} from '@/store/user'

export default {
  name: 'DatePicker',
  inheritAttrs: false,
  props: {
    customDateFormat: {
      type: String,
      required: false,
      default() {
        return ''
      }
    }
  },
  components: {
    'kendo-datepicker': DatePicker
  },
  mixins: [inputValidationMixin],
  setup() {
    const {settingValues} = storeToRefs(useUserStore())
    return {
      settingValues
    }
  },
  computed: {
    userDateFormat() {
      if (this.customDateFormat) {
        return this.customDateFormat
      }
      return this.settingValues?.User?.DateFormat?.substring(0, 10) ?? 'dd/MM/yyyy'
    },
    customAttrs() {
      let attrs = {...this.$attrs, class: ''}
      return attrs
    }
  },
  mounted() {
    this.$nextTick(() => {
      const iconElement = this.$refs.datePicker?.$el.querySelector('.k-button-icon')

      if (iconElement) {
        iconElement.classList.remove('k-i-calendar')
        iconElement.classList.add('custom-calendar-icon')
      }
    })
  }
}
</script>

<style lang="scss">
.datepicker-wrapper {
  .datepicker .k-picker-wrap {
    border: none;
  }

  .k-icon-button {
    margin-left: -1.25rem;
  }

  .request-only & {
    .k-icon-button {
      margin-top: -.5rem;
      margin-left: -1.75rem;
  }
  }

  .custom-calendar-icon {
    background-image: url('~/assets/icons/calendar-datepicker.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 1.2rem;
    height: 1.2rem;
    display: inline-block;
    content: '';
  }
}
</style>
