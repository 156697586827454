<template>
  <span
    v-text="quantityDisplay"
  />
</template>

<script>
export default {
  props: {
    quantity: {
      type: Number,
      default() {
        return null
      }
    },
    unit: {
      type: String,
      default() {
        return ''
      }
    }
  },
  computed: {
    quantityDisplay() {
      if (this.quantity) {
        const formattedQuantity = this.quantity.toLocaleString(this.$i18n.locale === 'en' ? 'en' : 'de')
        if (this.unit) {
          return this.$tv({messageKey: 'quantityAndUnit', messageValues: {quantity: formattedQuantity, unit: this.$tv(this.unit, 'packageUnits')}})
        }
        return formattedQuantity
      }
      else {
        return '-'
      }
    }
  }
}
</script>
