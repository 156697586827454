export const getEComText = function($tv, product) {
  let title = typeof(product?.primaryL2Category) === 'undefined' || product?.primaryL2Category === null ? null : $tv(product?.primaryL2Category.trim().toLowerCase(), 'categories')
  const design = typeof(product?.productDesign) === 'undefined' || product?.productDesign === null ? null : $tv(product?.productDesign.trim().toLowerCase(), 'categories', product?.productDesign.trim())
  if (design !== null) {
    title = title === null || title?.length === 0 ? `${design}` : `${title} | ${design}`
  }
  const dimensionText = product?.dimensionsString?.replace(/"/g, '')
  if (typeof(dimensionText) !== 'undefined') {
    title = title === null || title?.length === 0 ? `${dimensionText} ${$tv(product?.measurement, 'unitsOfMeasure')}` : `${title} | ${dimensionText} ${$tv(product.measurement, 'unitsOfMeasure')}`
  }
  const materialIdentifier = typeof(product?.materialPrimary) === 'undefined' ? null : product?.materialPrimary
  if (materialIdentifier !== null) {
    title = title === null || title?.length === 0 ? `${materialIdentifier}` : `${title} | ${materialIdentifier}`
  }
  // eslint-disable-next-line eqeqeq
  const specialValues = typeof(product?.characteristics) === 'undefined' ? null : product?.characteristics.filter(c => c.isSpecialValue && c.value != null).sort((a, b) => b.ranking - a.ranking)
  if (specialValues !== null && specialValues.length > 0) {
    specialValues.forEach(sv => {
      title = title === null || title?.length === 0 ? `${sv.value}` : `${title} | ${sv.value}`
    })
  }
  return title
}
