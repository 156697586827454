<template>
  <PopupBase
    :show="show"
    @close-pop-up="$emit('close-variants')"
    class="popup-successorVariants"
    :level="secondLayer ? 2 : 1"
    :close-on-outside-click="true"
  >
    <VariantItemList
      :show="show"
      :sku-array="skuArray"
      :context="context"
      @replace-cart-item="handleReplaceCartItem"
      @change-value="handleVariantItemChange"
      @close-variants="$emit('close-variants')"
    />
  </PopupBase>
</template>

<script>
import PopupBase from '~/components/popup/PopupBase.vue'
import VariantItemList from '~/components/product-detail/bb_components/VariantItemList.vue'

export default {
  name: 'PopupSuccessorVariants',
  components: {PopupBase, VariantItemList},
  props: {
    show: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    skuArray: {
      type: Array,
      default: () => []
    },
    secondLayer: {
      type: Boolean,
      default: false
    },
    context: {
      type: String,
      default()  {
        return 'product'
      }
    }
  },
  methods: {
    handleReplaceCartItem(sku) {
      this.$emit('replace-cart-item', sku)
    },
    handleVariantItemChange(sku) {
      this.$emit('change-value', sku)
    }
  }
}
</script>

<style lang="scss">
.popup-successorVariants {
  .popup .popup__wrapper {
    width: auto;
    max-width: 58rem;
  }
}
</style>
