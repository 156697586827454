<template>
  <div>
    <div
      class="variant-item"
      :class="{'is-current-variant': isCurrentVariant}"
    >
      <div
        class="variant-item-list__item-outer"
        :class="{
          'not-logged-in': !$auth.loggedIn,
        }"
      >
        <div class="variant-item-list__item-container">
          <div
            class="variant-item-list__item-header"
          >
            <span v-text="getVariantText(item?.variant)" />
            <span>{{ getProductText }}</span>
          </div>
          <div class="variant-item-list__item">
            <div class="variant-item-list__item-sku">
              <div
                v-tv="'ARTICLE_NO'"
                class="variant-label"
              />
              <NuxtLink
                class="variant-value-link"
                v-if="!isCurrentVariant"
                target="_blank"
                :to="getLocalePath(item)"
              >
                <PopUpIcon
                  class="popup-icon"
                />
                <SKUContainer
                  :product="item"
                  :multiple-lines="true"
                />
              </NuxtLink>
              <SKUContainer
                v-else
                :product="item"
                :multiple-lines="true"
              />
            </div>
            <div class="variant-item-list__item-status">
              <div
                v-tv:product="'status'"
                class="variant-label"
              />
              <b>{{ itemStatus }}&nbsp;</b>
            </div>
            <div
              class="variant-item-list__item-stock"
              v-if="$auth.loggedIn && !hasShowAlternativePlants"
            >
              <div
                class="variant-item-list__item-stock-th single"
                v-tv:product="'stock'"
              />
              <quantity-display
                :quantity="currentStock.quantity"
                :unit="currentStock.unit"
              />
            </div>
            <div
              class="variant-item-list__item-stock"
              v-if="$auth.loggedIn && hasShowAlternativePlants"
            >
              <div
                class="variant-item-list__item-stock-th"
                v-tv:product="'stockInformation'"
              />
              <div
                v-for="(st, index) in item?.stock"
                :key="index"
                class="quantity-flex"
              >
                <div class="variant-label">
                  {{ st.plant }}<span v-if="st.isDefault">*</span>:
                </div>
                <div class="quantity-value">
                  <quantity-display
                    :quantity="st.quantity"
                    :unit="st.unit"
                  />
                </div>
              </div>
              <div
                v-if="item?.stock?.length === 0"
                v-text="'-'"
              />
            </div>
            <div
              class="variant-item-list__item-price"
              v-if="$auth.loggedIn"
            >
              <div v-show="!itemPrice">
                <div class="variant-label">
                  {{ $tv('Pricing', 'product') }}
                </div>
                <b>
                  {{ $tv(itemPrice ? 'AvailablePrice' : 'RequestOnlyPrice', 'product') }}
                </b>
              </div>
            </div>
            <div class="variant-item-list__item-button">
              <ReactiveButton
                v-show="!hideButton"
                :is-primary-button="context !== 'product'"
                :button-text="buttonText"
                @click="handleDetailClick(item.sku)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReactiveButton from '~/components/ReactiveButton.vue'
import category_urls from '~/mixins/category_urls'
import availabilities_mixin from '~/mixins/availabilities'
import QuantityDisplay from '~/components/QuantityDisplay.vue'
import {getEComText} from '~/assets/js/utils/product'
import SKUContainer from '~/components/SKUContainer'
import PopUpIcon from '~/assets/icons/open_in_new.svg?component'

export default defineComponent({
  components: {
    ReactiveButton,
    QuantityDisplay,
    SKUContainer,
    PopUpIcon
  },
  mixins: [category_urls, availabilities_mixin],
  props: {
    itemNo: {
      type: Number,
      required: true
    },
    item: {
      type: Object,
      default() {
        return {
          sku: '',
          status: '',
          stock: [],
          price: 0,
          isSuccessor: false,
          productDesign: ''
        }
      }
    },
    hasShowAlternativePlants: {
      type: Boolean,
      default() {
        return false
      }
    },
    showHeader: {
      type: Boolean,
      default() {
        return true
      }
    },
    buttonText: {
      type: String,
      default() {
        return ''
      }
    },
    hideButton: {
      type: Boolean,
      default() {
        return false
      }
    },
    isCurrentVariant: {
      type: Boolean,
      default() {
        return false
      }
    },
    isPrimarybuttonClass: {
      type: Boolean,
      default() {
        return false
      }
    },
    context: {
      type: String,
      default()  {
        return 'product'
      }
    }
  },
  computed: {
    currentStock() {
      if (this.item?.stock?.length > 0) {
        return this.item.stock[0]
      }
      return this.item?.currentStock ?? 0
    },
    itemPrice() {
      return this.item?.price ?? null
    },
    itemStatus() {
      return this.item?.status ? this.$tv(this.item.status, 'status') : '-'
    },
    getProductText() {
      return getEComText(this.$tv, this.item)
    }
  },
  methods: {
    handleDetailClick(sku) {
      this.$emit('close-pop-up', true)
      this.$nextTick(() => {
        this.$emit('detail-click', sku)
      })
    },
    getLocalePath(item) {
      return this.localePath('/categories/' + this.getCategoryUrlFromTechnicalName(item?.productDesign, this.$i18n.locale) + '/products' + '/' + item?.sku, this.$i18n.locale)
    }
  }
})
</script>

<style lang="scss">
.variant-item {
  box-shadow: 0 .1875rem .375rem $color--card__shadow;
  padding: 1rem;
  width: calc(100% - 2rem);
  margin: 0 0 1rem;

  &.is-current-variant {
    box-shadow: none;
    background-color: $color__content--background__secondary;
  }
}

.variant-item-list {
  &__item {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    z-index: 1;

    &-header {
      font-weight: $base-text-font-weight-semi-bold;
      padding-bottom: .25rem;
      font-size: $heading-h6-font-size;

      span:nth-child(2) {
        margin-left: .5rem;
        font-weight: $base-text-font-weight-light;
        font-size: $base-text-font-size;
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      line-height: 1.625rem;

      &.variant-item-list__item-button {
        justify-content: flex-end;
      }
    }

    &-container {
      flex: 1;
      align-items: stretch;
    }

    &-sku {
      flex: 0 0 7.625rem;

      .popup-icon {
        margin-right: .5rem;
        color: $color__fst__primary;
      }

      .variant-value-link {
        font-weight: $base-text-font-weight-semi-bold;
      }
    }

    &-status {
      flex: 0 0 7.625rem;
    }

    &-stock {
      flex: 0 0 12rem;

      &-th {
        font-weight: $base-text-font-weight-semi-bold;

        &.single {
          font-weight: $base-text-font-weight-light;
        }
      }

      .quantity-flex {
        display: flex;
        justify-content: space-between;
      }

      .variant-label {
        display: inline-block;
      }

      .quantity-value {
        font-weight: $base-text-font-weight-semi-bold;
        display: inline-block;
        padding-left: .25rem;
      }
    }

    .variant-label {
        font-weight:  $base-text-font-weight-light;
    }

    &-button {
      flex: 0 0 11.5625rem;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: flex-end;

      .k-button {
        font-size: map-get($base-text-button, font-size );
      }
    }

    &-price {
      padding-left: .25rem;
      width: 15%;
    }

    &-outer {
      position: relative;
      display: block;
      align-items: center;
      justify-content: flex-start;
      z-index: 0;
    }
  }
}

.not-logged-in .variant-item-list__item-sku-status {
  width: 75%;
}
</style>
