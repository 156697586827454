<template>
  <div
    class="skeleton__variant-successor"
  >
    <div class="skeleton__variant-successor__header">
      <Skeleton class="skeleton__variant-successor__header" />
    </div>

    <Skeleton class="skeleton__variant-successor__text" />
    <div
      class="skeleton__variant-successor__items"
      v-for="(row, index) in dummyArray"
      :key="row.index"
      :class="{ 'first-item': index === 0, 'other-item': index !== 0 }"
    >
      <div>
        <Skeleton class="skeleton__variant-successor__items__current" />
        <div
          class="skeleton__variant-successor__items__data-title"
          v-for="(_, rowIndex) in 2"
          :key="'row_' + rowIndex"
        >
          <div
            v-for="insideIndex in insideDummyArray"
            :key="'title_' + rowIndex + '_' + insideIndex"
          >
            <Skeleton class="skeleton__variant-successor__items__data-title__text" />
          </div>
        </div>
      </div>
      <div
        v-if="index !== 0"
        class="skeleton__variant-successor__items__container"
      >
        <Skeleton
          class="skeleton__variant-successor__items__container__button"
          :shape="'rectangle'"
        />
      </div>
    </div>
    <Skeleton class="skeleton__variant-successor__text inside-skeleton" />
  </div>
</template>
<script>
import {Skeleton} from '@progress/kendo-vue-indicators'

export default {
  components: {
    Skeleton
  },
  data() {
    const dummyArrayLength = 2
    const insideDummyArrayLength = 3
    return {
      dummyArray: Array.from({length: dummyArrayLength}, (_, i) => i),
      insideDummyArray: Array.from({length: insideDummyArrayLength}, (_, i) => i)
    }
  }
}
</script>
